import { useCart } from "medusa-react"
import React, { FC, useMemo, useRef } from "react"
import { StoreGetProductsParams } from "@medusajs/medusa/dist/api/routes/store/products/list-products"
import { useInfiniteQuery } from "react-query"
import { fetchProductsList } from "@lib/data"
import usePreviews from "@lib/hooks/use-previews"
import Slider from "react-slick"
import ProductPreview from "@modules/products/components/product-preview"
import { SliderProp } from "@lib/data/_types"
import sortProductsByColor from "@lib/util/product-sort-color"
import { useRouter } from "next/router"
import { ProductPreviewType } from "../../../../types/global"

const ProductSlider: FC<{
    item: SliderProp
}> = ({ item }) => {
    const { cart } = useCart()
    const { pathname } = useRouter()
    const swiperElRef = useRef(null)
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    const queryParams: StoreGetProductsParams = useMemo(() => {
        const params: StoreGetProductsParams = {}
        params.id = item?.products ?? []
        params.cart_id = cart?.id
        return params
    }, [cart])
    const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
        useInfiniteQuery(
            [`pslider`, queryParams, "v2"],
            ({ pageParam }) => fetchProductsList({ pageParam, queryParams }),
            {
                getNextPageParam: (lastPage) => lastPage.nextPage
            }
        )
    const previews = usePreviews({
        pages: data?.pages,
        region: cart?.region
    })
    return (
        <>
            <button
                className="prev-button rounded-full border border-brand p-3 text-brand hover:bg-brand hover:text-white w-[50px] h-[50px]"
                onClick={(e) => {
                    e.preventDefault()
                    if (swiperElRef.current) {
                        // @ts-ignore
                        swiperElRef.current.slickPrev()
                    }
                }}
            >
                <span className="sr-only">Previous Slide</span>
                <svg
                    className="h-5 w-5 -rotate-180 transform"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                </svg>
            </button>
            <div className={"w-[calc(100%-100px)]"}>
                <Slider ref={swiperElRef} {...settings}>
                    {(pathname !== "/"
                        ? sortProductsByColor(previews)
                        : previews.sort(
                              (
                                  productA: ProductPreviewType,
                                  productB: ProductPreviewType
                              ) => {
                                  const colorA =
                                      productA?.title
                                          ?.toLowerCase()
                                          ?.match(
                                              /geel|zwart|rustiek|walnoot|ebony|naturel/i
                                          )?.[0] ?? "naturel"
                                  const colorB =
                                      productB?.title
                                          ?.toLowerCase()
                                          .match(
                                              /geel|zwart|rustiek|walnoot|ebony|naturel/i
                                          )?.[0] ?? "naturel"

                                  const colorValues: { [key: string]: number } =
                                      {
                                          naturel: 3,
                                          zwart: 2,
                                          walnoot: 1,
                                          rustiek: 0
                                      }

                                  if (
                                      colorValues[colorA] < colorValues[colorB]
                                  ) {
                                      return -1
                                  } else if (
                                      colorValues[colorA] > colorValues[colorB]
                                  ) {
                                      return 1
                                  } else {
                                      return 0
                                  }
                              }
                          )
                    ).map((p, i) => (
                        <div key={`swipe-${i}`} className={"p-4"}>
                            <ProductPreview {...p} doubleColl={false} />
                        </div>
                    ))}
                </Slider>
            </div>
            <button
                className="next-button rounded-full border border-brand p-3 text-brand hover:bg-brand hover:text-white w-[50px] h-[50px]"
                onClick={(e) => {
                    e.preventDefault()
                    if (swiperElRef.current) {
                        // @ts-ignore
                        swiperElRef.current.slickNext()
                    }
                }}
            >
                <span className="sr-only">Next Slide</span>
                <svg
                    className="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                </svg>
            </button>
        </>
    )
}

export default ProductSlider
