import { ProductPreviewType } from "../../types/global"

const sortProductsByColor = (products: ProductPreviewType[]) => {
    return products.sort(
        (productA: ProductPreviewType, productB: ProductPreviewType) => {
            if (
                productA?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                ) &&
                productB?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                )
            )
                return 0
            if (
                productA?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                ) &&
                !productB?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                )
            )
                return 1
            if (
                !productA?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                ) &&
                productB?.tags?.find(
                    (tag) => tag === "ptag_01H4G4MQN3Z2T3PBGEY3RTTYXM"
                )
            )
                return -1
            if (
                productA.title.match(
                    /geel|zwart|rustiek|walnoot|ebony|naturel/i
                ) === null ||
                productB.title.match(
                    /geel|zwart|rustiek|walnoot|ebony|naturel/i
                ) === null
            )
                return 0
            const colorA =
                productA?.title
                    ?.toLowerCase()
                    ?.match(/geel|zwart|rustiek|walnoot|ebony|naturel/i)?.[0] ??
                "naturel"
            const colorB =
                productB?.title
                    ?.toLowerCase()
                    .match(/geel|zwart|rustiek|walnoot|ebony|naturel/i)?.[0] ??
                "naturel"

            const colorValues: { [key: string]: number } = {
                naturel: 0,
                zwart: 1,
                walnoot: 2,
                rustiek: 3,
                ebony: 4,
                geel: 5
            }

            if (colorValues[colorA] < colorValues[colorB]) {
                return -1
            } else if (colorValues[colorA] > colorValues[colorB]) {
                return 1
            } else {
                return 0
            }
        }
    )
}

export default sortProductsByColor
